import axiosClient from "./customAxios";


// Obtenir tous les champs 
export const getChamps = async () => {
  try {
    const response = await axiosClient.get(process.env.VUE_APP_ROOT_API + `champs`);
    return response.data;
  } catch (error) {
      //console.log({error})
      throw error;

  }
}


// Ajouter un champ 
export const addChamps = async (champ) => {
  //console.log({champ});
  const data = JSON.stringify(champ);
  try {
    // const response = await axios.post(process.env.VUE_APP_ROOT_API + `champs/${f}`, data, config);
    const response = await axiosClient.post(process.env.VUE_APP_ROOT_API + `champs/${f}`, data);
    return response;
  } catch (error) {
      throw error;
  }
}