import axios from "axios";
import store from "../store/index";
import { generateNewAccessToken } from "../api/auth";
import router from "../router";
import { jwtDecode } from "jwt-decode";

// Create a new axios instance with default configuration
const axiosClient = axios.create();

// Request interceptor
axiosClient.interceptors.request.use(
    (config) => {
      const access_token = store.getters.access_token;
  
      if (!navigator.onLine) {
        alert("Pas de connexion internet");
        return location.reload();
      } else if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
  
      return config;
    },
    (error) => Promise.reject(error),
  );

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    
    //console.log({TOTOYT: error})
    if (error.response.status === 401) {
      try {
        // console.log('OOOOOOOOOOOOOOO')
        store.commit('SET_REFRESHING_TOKEN', true);

        const refreshToken = store.getters.refresh_token;
        const resp = await generateNewAccessToken(refreshToken);

        // console.log({Refresher: resp})

        store.commit('SET_EXPIRE_IN', resp.expires_in);
        store.commit('SET_ACCESS_TOKEN', resp.access_token);

        const userData = jwtDecode(resp.id_token)
        store.commit('SET_USER', userData);
        store.commit('SET_PHOTO_URL', userData.picture);

        const config = error.config;
        config.headers.Authorization = `Bearer ${resp.access_token}`;

        // console.log('UUUUUUUUUUUUUUUUU')
        return axios.request(config);
      } catch (refreshError) {
        // console.log('IIIIIIIIIIIIIIIIII',{refreshError})
        if (refreshError.response.status === 400 && store.getters.refreshingToken === true) {
            // console.log('RRRRRRRRRRRRRRRRRRRR')
            store.commit('SET_REFRESHING_TOKEN', false);
            
            localStorage.clear()
            router.replace('/login');
            window.location.reload()
            alert("Votre session a expirée, reconnectez vous à nouveau")
            // store.commit('SET_DIALOG_SESSION_EXPIRED', true)
        } 
      } 
    } 
    else if (error.response.status === 500) {
      // console.log('SSSSSSSSSSSSSSSSSSS')
      const errorMessage = "Erreur interne du serveur";
      const errorData = {
        statusCode: 500,
        message: errorMessage,
        data: {
          path: error.response.config.url,
        },
      };
      throw new Error(errorMessage, errorData);
    }
    // console.log('TTTTTTTTTTTTTTTTTTTTTTTTTTT')
    return Promise.reject(error);
  },
);

export default axiosClient;